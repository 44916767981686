import React, { Component } from 'react';
import Form from 'react-web-formo';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import shortid from 'shortid';
import { Button } from '@material-ui/core';
import moment from 'moment';
import * as _ from 'lodash';

import Spinner from '../../../../common/spinner';
import SnackbarMessage from '../../../../common/snackbarMessage';
import * as types from '../../../../actions/types';
import {
  resetErrorMessage,
  resetSuccessMessage,
} from '../../../userOnBoarding/actions/actions';
import {
  getReportDetails,
  createFormData,
  setSelectedFieldValue,
  handleImagesUploadAndDownload,
  handleDocumentUpload,
  getTeamDesignations,
  getOTPForForm,
} from '../../actions/report';
import {
  getTeamUserDetails,
  getUserDirectoryDesignation,
} from '../../actions/home';
import Catalog from './../catalog';
import Lookup from './../lookup';
import Checklist from './../checklist';
import Select from './../select';
import UserDirectory from './../userDirectory';
import {
  isNull,
  unsupportedMessage,
  getPickerDesigOptions,
  getPickerDefaultValue,
  validateEmail,
  validateMobNumber,
  getUserDefaultValue,
} from '../../../../utils/helper';
import ImagePreviewModal from './../inbox/imagePreviewModal';
import AlertDailog from './../../component/alertDailog';
import SignatureModal from '../inbox/signatureModal';
import {
  getAssignmentType,
  deleteDocumentFile,
  downloadDocument,
  processUserDirectoryField,
} from './helper';
import { setCustomToken } from './../../../../fireStoreConfig/fireStore';
import '../../styles/form/createForm.css';
import withRouter from 'withRouter';

shortid.characters(
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@'
);

interface IState {
  fields: any[];
  templateName: string;
  openCatalogModal: boolean;
  extProps: any;
  openLookupModal: boolean;
  formData: any;
  openChecklistModal: boolean;
  openSelectModal: boolean;
  openUserDirectoryModal: boolean;
  attachmentUploadStatus: string;
  isImagePicked: boolean;
  openImgModal: boolean;
  modalImgData: any;
  openAlertDialog: boolean;
  alertMessage: string;
  docUpload: boolean;
  deleteDocument: boolean;
  deleteDocDialog: boolean;
  deleteDocData: any;
  refData: any;
  openSignatureModal: boolean;
  signatureAttributes: any;
}

interface IProps {
  getReportDetailsSuccess: boolean;
  createFormDataSuccess: boolean;
  reportDetails: any;
  createFormDataResponse: any;
  reportDetailsSpinner: boolean;
  createFormDataSpinner: boolean;
  error: {
    displayMessage: string;
  };
  success: {
    displayMessage: string;
  };
  open: boolean;
  dispatch: Dispatch;
  resetErrorMessage: () => void;
  resetSuccessMessage: () => void;
  userTeamDetails: any;
  teamUserDetailsSuccess: boolean;
  timedOut: boolean;
  externalProps: any;
  getReportDetails: (templateId: string, teamId: string, resolve: any) => void;
  createFormData: (
    templateId: string,
    viewName: string,
    formData: any,
    isImagePicked: boolean
  ) => void;
  getTeamUserDetails: (teamId: string, userId: any) => void;
  selectedMsg: string;
  setSelectedFieldValue: any;
  handleImagesUploadAndDownload: any;
  handleDocumentUpload: any;
  teamdesignations: any;
  teamdesignationsSpinner: boolean;
  teamdesignationsSuccess: boolean;
  teamdesignationsFailure: boolean;
  getTeamDesignations: (teamId: string) => void;
  router: any;
  getOTPForForm: (
    userName: string,
    loginType: string,
    resolve: any,
    reject: any
  ) => void;
}

class CreateLookupForm extends Component<IProps, IState> {
  state = {
    fields: [],
    templateName: '',
    openCatalogModal: false,
    extProps: {},
    openLookupModal: false,
    formData: null,
    openChecklistModal: false,
    openSelectModal: false,
    openUserDirectoryModal: false,
    attachmentUploadStatus: 'done',
    isImagePicked: false,
    openImgModal: false,
    modalImgData: null,
    openAlertDialog: false,
    alertMessage: '',
    docUpload: false,
    deleteDocument: false,
    deleteDocDialog: false,
    deleteDocData: {},
    refData: {},
    openSignatureModal: false,
    signatureAttributes: null,
  };
  startTime = moment().utc().valueOf();
  instanceId = shortid.generate();
  formGenerator: any;
  props: any;

  componentDidMount() {
    if (this.props.externalProps) {
      this.loadData(this.props.router.params.templateId);
    } else {
      this.backRoute();
    }
    setCustomToken(sessionStorage.getItem('accessToken'));
  }

  componentDidUpdate() {
    if (this.props.getReportDetailsSuccess) {
      this.props.dispatch({
        type: types.RESET_REPORT_DETAILS_REQUEST,
      });
      this.props.getTeamUserDetails(
        this.props.router.params.teamId,
        sessionStorage.getItem('userId')
      );
    }
    if (this.props.createFormDataSuccess) {
      if (!this.props.timedOut) {
        if (this.props.createFormDataResponse['_mo_instance_id']) {
          const fd: any = !isNull(this.state.formData)
            ? this.state.formData
            : {};
          this.props.externalProps.updateValue(
            this.props.externalProps.attributes.name,
            {
              ...fd,
              instance_id: fd['_mo_instance_id'],
            }
          );
          this.backRoute();
        } else {
          this.props.router.navigate(`/member/home`);
        }
      }
      this.props.dispatch({
        type: types.RESET_CREATE_FORM_DATA_REQUEST,
      });
    }
    if (this.props.teamUserDetailsSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_TEAM_USER_DETAILS_REQUEST,
      });
      const designationName =
        this.props.userTeamDetails && this.props.userTeamDetails.designation
          ? this.props.userTeamDetails.designation.designation_name
          : null;
      sessionStorage.setItem('desigName', designationName);
      this.props.getTeamDesignations(this.props.router.params.teamId);
    }
    if (
      this.props.teamdesignationsSuccess ||
      this.props.teamdesignationsFailure
    ) {
      this.props.dispatch({
        type: types.RESET_GET_TEAM_DESIGNATIONS_REQUEST,
      });
      this.getCreateFields(this.props.reportDetails);
    }
  }

  /**
   * get report details
   */
  loadData(templateId: any) {
    return new Promise((resolve) => {
      this.props.getReportDetails(
        templateId,
        this.props.router.params.teamId,
        resolve
      );
    });
  }

  getLogginedDesigId = () => {
    return this.props.userTeamDetails &&
      this.props.userTeamDetails.designation &&
      this.props.userTeamDetails.designation.designation_id
      ? this.props.userTeamDetails.designation.designation_id
      : null;
  };

  /**
   * Create fields for form0
   */
  getCreateFields = async (workflow: any) => {
    const formatTemplate =
      !isNull(workflow) && !isNull(workflow.template)
        ? JSON.parse(workflow.template)
        : null;
    const fields =
      !isNull(formatTemplate) && !isNull(formatTemplate.fields)
        ? formatTemplate.fields
        : [];
    const promises: Promise<void>[] = [];
    const createFields =
      !isNull(formatTemplate) &&
      !isNull(formatTemplate.views) &&
      !isNull(formatTemplate.views.create) &&
      !isNull(formatTemplate.views.create.fields)
        ? formatTemplate.views.create.fields
        : [];
    const data: any = [];
    if (fields.length > 0 && createFields.length > 0) {
      createFields.map((item: any) => {
        let field = _.find(fields, {
          name: item,
        });
        if (field) {
          /**
           * For status_picker and picker, get stage specific options to render and add -Select- as first value
           */
          if (['status_picker', 'picker'].includes(field.type)) {
            if (field.filter_options && field.filter_options.length > 0) {
              field = this.updateOption(field);
            } else {
              const options =
                field.type === 'picker'
                  ? getPickerDesigOptions(field, this.props.teamdesignations)
                  : field['options'];
              const index = _.findIndex(
                options,
                (option: any) => option === '-Select-'
              );
              if (index === -1) options.unshift('-Select-');
              field['options'] = options;
              if (field.type === 'picker') {
                field['defaultValue'] = getPickerDefaultValue(
                  field,
                  this.props.teamdesignations,
                  this.getLogginedDesigId()
                );
              }
            }
          }
          if (field.type === 'text') {
            const value = getUserDefaultValue(
              field,
              this.props.userTeamDetails,
              this.props.teamdesignations,
              this.getLogginedDesigId()
            );
            if (!isNull(value)) field['defaultValue'] = value;
          }
          if (field.type === 'user_directory') {
            const assignmentType = getAssignmentType(
              field.config.assignment_type
            );
            const promise = new Promise<void>((resolve, reject) => {
              this.props.getUserDirectoryDesignation(
                assignmentType,
                resolve,
                reject
              );
            }).then((designationData: any) => {
              processUserDirectoryField(
                field,
                designationData,
                this.props.userTeamDetails
              );
            });
            promises.push(promise);
          }
          if (field.type === 'email') {
            const value = getUserDefaultValue(
              field,
              this.props.userTeamDetails,
              this.props.teamdesignations,
              this.getLogginedDesigId()
            );
            if (!isNull(value) && validateEmail(value)) {
              field['defaultValue'] = value;
            } else if (
              isNull(field['defaultValue']) ||
              (!isNull(field['defaultValue']) &&
                !validateEmail(field['defaultValue']))
            ) {
              field['defaultValue'] = '';
            }
          }
          if (field.type === 'phone') {
            const value = getUserDefaultValue(
              field,
              this.props.userTeamDetails,
              this.props.teamdesignations,
              this.getLogginedDesigId()
            );
            if (!isNull(value) && validateMobNumber(value)) {
              field['defaultValue'] = value;
            } else if (
              isNull(field['defaultValue']) ||
              (!isNull(field['defaultValue']) &&
                !validateMobNumber(field['defaultValue']))
            ) {
              field['defaultValue'] = '';
            }
          }
          if (field.type === 'number') {
            if (isNaN(field['defaultValue'])) {
              field['defaultValue'] = '';
            }
          }
          data.push(field);
        }
        return null;
      });
    }
    await Promise.all(promises);
    this.setState({
      fields: data,
      templateName: this.props.reportDetails.name,
    });
  };

  /**
   * Get stage specific options to render and add -Select- as first value
   */
  updateOption = (field: any) => {
    const options = field.filter_options;
    const stage = 'create';
    const filterOption = _.find(options, stage);

    if (filterOption) {
      const stageOption = filterOption[stage];
      const index = _.findIndex(
        stageOption,
        (option: any) => option === '-Select-'
      );
      if (index === -1) stageOption.unshift('-Select-');
      field['options'] = stageOption;
    }
    return field;
  };

  scrollToTop = () => {
    setTimeout(() => {
      const element: any = document.getElementById('create-form-root');
      element.scrollIntoView({ behavior: 'smooth' });
    }, 1);
  };

  openCustomDataView = (extProps: any) => {
    this.closeLookupModal();
    this.closeChecklist();
    this.closeSelectModal();
    this.closeUserDirectoryModal();
    this.setState({ openCatalogModal: true, extProps }, () => {
      this.scrollToTop();
    });
  };

  closeCustomDataView = () => {
    this.setState({ openCatalogModal: false });
  };

  openLookupView = (extProps: any) => {
    this.closeCustomDataView();
    this.closeChecklist();
    this.closeSelectModal();
    this.closeUserDirectoryModal();
    this.setState({ openLookupModal: true, extProps }, () => {
      this.scrollToTop();
    });
  };

  closeLookupModal = () => {
    this.setState({ openLookupModal: false });
  };

  /**
   * Add checklist view
   */
  openCheckList = (extProps: any) => {
    this.closeCustomDataView();
    this.closeLookupModal();
    this.closeSelectModal();
    this.closeUserDirectoryModal();
    this.setState({ openChecklistModal: true, extProps }, () => {
      this.scrollToTop();
    });
  };

  closeChecklist = () => {
    this.setState({ openChecklistModal: false });
  };

  handleClose = () => {
    this.props.resetErrorMessage();
    this.props.resetSuccessMessage();
    this.props.setSelectedFieldValue(false, '');
  };

  /**
   * Open select modal
   */
  openSelect = (extProps: any) => {
    this.closeCustomDataView();
    this.closeLookupModal();
    this.closeChecklist();
    this.closeUserDirectoryModal();
    this.setState({ openSelectModal: true, extProps }, () => {
      this.scrollToTop();
    });
  };

  closeSelectModal = () => {
    this.setState({ openSelectModal: false });
  };

  /**
   * Open user directory modal
   */
  openUserDirectory = (extProps: any) => {
    this.closeCustomDataView();
    this.closeLookupModal();
    this.closeChecklist();
    this.closeSelectModal();
    this.setState({ openUserDirectoryModal: true, extProps }, () => {
      this.scrollToTop();
    });
  };

  closeUserDirectoryModal = () => {
    this.setState({ openUserDirectoryModal: false });
  };

  /**
   * Add _mo_instance_id,_mo_start_time and _mo_end_time values to form data
   */
  submitForm = () => {
    const formData = this.formGenerator.getValues();
    if (formData) {
      const endTime = moment().utc().valueOf();
      const recordedTime = moment().utc().valueOf();
      formData['_mo_instance_id'] = this.instanceId;
      formData['_mo_start_time'] = this.startTime;
      formData['_mo_recorded_at'] = recordedTime;
      formData['_mo_end_time'] = endTime;
      this.setState({ formData });
      this.props.createFormData(
        this.props.router.params.templateId,
        '',
        formData,
        this.state.isImagePicked
      );
    }
  };

  getValues = (fields: any) => {
    const data = {};
    if (fields.length > 0) {
      fields.map((item: any) => {
        data[item.name] = item.value;
        return null;
      });
    }
    return data;
  };

  backRoute = () => {
    this.handleClose();
    if (
      this.props.router.location.state &&
      this.props.router.location.state['parentTemplateId']
    ) {
      const values = this.getValues(this.props.router.location.state['fields']);
      this.props.router.navigate(
        this.props.router.location.state['returnPathname']
          ? this.props.router.location.state['returnPathname']
          : `/member/${this.props.router.params.teamId}/create/${this.props.router.location.state['parentTemplateId']}`,
        {
          state: {
            fieldValues: values,
            fields: this.props.router.location.state['fields'],
            templateName: this.props.router.location.state['templateName'],
          },
        }
      );
    } else {
      this.props.router.navigate(`/member/home`);
    }
  };

  renderComponent = (extProps: any) => {
    const { attributes } = extProps;
    if (attributes && typeof attributes['type'] !== 'undefined') {
      const { type } = attributes;
      switch (type) {
        case 'lookup':
          this.openLookupView(extProps);
          break;
        case 'customDataView':
          this.openCustomDataView(extProps);
          break;
        case 'checklist':
          this.openCheckList(extProps);
          break;
        case 'select':
          this.openSelect(extProps);
          break;
        case 'user_directory':
          this.openUserDirectory(extProps);
          break;
      }
    }
  };

  getButtonTitle = () => {
    if (
      this.state.attachmentUploadStatus === 'inProgress' ||
      this.state.docUpload
    ) {
      return `Uploading`;
    } else if (this.state.deleteDocument) {
      return 'Deleting record';
    }

    return `Submit`;
  };

  handleImagesUploadAndDownload = (
    attributes: any,
    doc: any,
    actionType: any
  ) => {
    try {
      if (typeof doc !== 'undefined' && !isNull(doc) && attributes) {
        this.setState({
          attachmentUploadStatus: 'inProgress',
        });

        new Promise((resolve, reject) => {
          this.props.handleImagesUploadAndDownload(
            this.props.router.params.templateId,
            this.props.router.params.teamId,
            this.instanceId,
            attributes['name'],
            doc,
            actionType,
            resolve,
            reject
          );
        })
          .then((data) => {
            if (!isNull(data) && Array.isArray(data) && data.length) {
              this.setState(
                {
                  attachmentUploadStatus: 'done',
                  isImagePicked: actionType === 'write' ? true : false,
                },
                () => {
                  this.formGenerator.onValueChange(attributes['name'], data);
                }
              );
            }
          })
          .catch(() => {
            this.setState(
              {
                attachmentUploadStatus: 'done',
              },
              () => {
                this.formGenerator.onValueChange(attributes['name'], null);
              }
            );
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  callUploadDocument = (attributes: any, doc: any) => {
    try {
      new Promise((resolve, reject) => {
        this.props.handleDocumentUpload(
          this.props.router.params.teamId,
          this.props.router.params.templateId,
          this.instanceId,
          doc,
          resolve,
          reject
        );
      })
        .then((data) => {
          if (!isNull(data) && Array.isArray(data) && data.length) {
            this.setState(
              {
                docUpload: false,
              },
              () => {
                const prevValues = attributes['value']
                  ? attributes['value']
                  : [];
                this.formGenerator.onValueChange(attributes['name'], [
                  ...prevValues,
                  ...data,
                ]);
              }
            );
          }
        })
        .catch(() => {
          this.setState(
            {
              docUpload: false,
            },
            () => {
              const prevValues = attributes['value'] ? attributes['value'] : [];
              this.formGenerator.onValueChange(attributes['name'], prevValues);
            }
          );
        });
    } catch (err) {
      console.log(err);
    }
  };

  handleDocumentUpload = (attributes: any, doc: any) => {
    try {
      if (typeof doc !== 'undefined' && !isNull(doc) && attributes) {
        this.setState({ docUpload: true });
        this.callUploadDocument(attributes, doc);
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleDocumentUpdateAndDownload = (
    attributes: any,
    doc: any,
    actionType: any
  ) => {
    const { type } = attributes;
    switch (type) {
      case 'image':
      case 'signature':
        this.handleImagesUploadAndDownload(attributes, doc, actionType);
        break;
      case 'document':
        this.handleDocumentUpload(attributes, doc);
        break;
      default:
        return;
    }
  };

  handleDeleteDocument = async (attributes: any, doc: any) => {
    try {
      if (typeof doc !== 'undefined' && !isNull(doc) && attributes) {
        const filePath = !isNull(doc['file_path']) ? doc['file_path'] : '';
        if (!isNull(filePath)) {
          this.setState({
            deleteDocument: true,
          });
          const deleteRes = await deleteDocumentFile(
            attributes,
            filePath,
            this.formGenerator
          );
          this.setState({ deleteDocument: deleteRes, deleteDocData: {} });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  handlePreviewDocument = (doc: any) => {
    try {
      downloadDocument(doc);
    } catch (err) {
      console.log(err);
    }
  };

  handleOpenImageModal = (modalImgData: any) => {
    this.setState({ openImgModal: true, modalImgData });
  };

  closeImgModal = () => {
    this.setState({ openImgModal: false, modalImgData: null });
  };

  handleOpenAlertDialog = (msg: string) => {
    this.setState({ openAlertDialog: true, alertMessage: msg });
  };

  closeAlertDailog = () => {
    this.setState({ openAlertDialog: false, alertMessage: '' });
  };

  handleOpenDeleteDocDailog = (data: any) => {
    this.setState({
      deleteDocDialog: true,
      deleteDocData: data,
    });
  };

  closeDeleteDocDailog = (option: string) => {
    if (option === 'yes') {
      this.setState({ deleteDocDialog: false }, () => {
        this.handleDeleteDocument(
          this.state.deleteDocData['attributes'],
          this.state.deleteDocData['doc']
        );
      });
    } else {
      this.setState({
        deleteDocDialog: false,
        deleteDocData: null,
      });
    }
  };

  getOTP = (attributes: any, userName: string, userNameType: string) => {
    new Promise((resolve, reject) => {
      this.props.getOTPForForm(userName, userNameType, resolve, reject);
    })
      .then((data) => {
        attributes['res'] = data;
        const { fields }: { fields: any } = this.state;
        const index = _.findIndex(fields, { name: attributes.name });
        fields[index] = { ...attributes };
        this.setState({ fields: fields });
      })
      .catch((error: any) => {
        console.error('Error fetching OTP:', error);
      });
  };

  render() {
    return (
      <div id="create-form-root">
        <div className="create-form-wrapper">
          <div className="create-form-header">
            <i
              className="fas fa-arrow-left"
              style={{ paddingRight: '20px' }}
              onClick={() => {
                this.backRoute();
              }}
            />
            <p>Create {this.state.templateName}</p>
          </div>
          {this.props.reportDetailsSpinner ||
          this.props.createFormDataSpinner ||
          this.props.teamdesignationsSpinner ? (
            <Spinner />
          ) : (
            ''
          )}
          <div className="form-content">
            <div className="form-left">
              {!isNull(unsupportedMessage(this.state.fields)) && (
                <p style={{ color: '#FA9917', margin: '10px' }}>
                  {unsupportedMessage(this.state.fields)}
                </p>
              )}
              {this.state.fields.length > 0 ? (
                <Form
                  className="form"
                  ref={(c: any) => (this.formGenerator = c)}
                  fields={this.state.fields}
                  formSubmissionType="create"
                  renderComponent={this.renderComponent}
                  handleDocumentUpdateAndDownload={
                    this.handleDocumentUpdateAndDownload
                  }
                  handleOpenImageModal={this.handleOpenImageModal}
                  openAlertModal={this.handleOpenAlertDialog}
                  handleOpenDeleteDocDailog={this.handleOpenDeleteDocDailog}
                  handlePreviewDocument={this.handlePreviewDocument}
                  getOtp={this.getOTP}
                  openSignatureModal={(attributes: any) =>
                    this.setState({
                      openSignatureModal: true,
                      signatureAttributes: attributes,
                    })
                  }
                  refData={this.state.refData}
                />
              ) : (
                ''
              )}
              <div className="buttons-wrapper">
                <Button
                  className={
                    this.props.createFormDataSpinner ||
                    this.state.attachmentUploadStatus !== 'done' ||
                    this.state.docUpload ||
                    this.state.deleteDocument
                      ? 'form-disabled-button'
                      : 'form-create-button'
                  }
                  onClick={() => this.submitForm()}
                  disabled={
                    this.props.createFormDataSpinner ||
                    this.state.attachmentUploadStatus !== 'done' ||
                    this.state.docUpload ||
                    this.state.deleteDocument
                  }
                >
                  {this.getButtonTitle()}
                </Button>
              </div>
            </div>
            {this.state.openCatalogModal ? (
              <Catalog
                open={this.state.openCatalogModal}
                attributes={
                  this.state.extProps && this.state.extProps['attributes']
                }
                extProps={this.state.extProps}
                closeModal={this.closeCustomDataView}
              />
            ) : null}
            {this.state.openLookupModal ? (
              <Lookup
                attributes={
                  this.state.extProps && this.state.extProps['attributes']
                }
                extProps={this.state.extProps}
                closeModal={this.closeLookupModal}
                isAddLookupEnable={false}
              />
            ) : null}
            {this.state.openChecklistModal ? (
              <Checklist
                attributes={
                  this.state.extProps && this.state.extProps['attributes']
                }
                extProps={this.state.extProps}
                closeModal={this.closeChecklist}
              />
            ) : null}
            {this.state.openSelectModal ? (
              <Select
                attributes={
                  this.state.extProps && this.state.extProps['attributes']
                }
                extProps={this.state.extProps}
                closeModal={this.closeSelectModal}
              />
            ) : null}
            {this.state.openUserDirectoryModal ? (
              <UserDirectory
                attributes={
                  this.state.extProps && this.state.extProps['attributes']
                }
                extProps={this.state.extProps}
                closeModal={this.closeUserDirectoryModal}
              />
            ) : null}
            {this.state.openImgModal ? (
              <ImagePreviewModal
                open={this.state.openImgModal}
                closeModal={this.closeImgModal}
                imgObj={this.state.modalImgData}
              />
            ) : (
              ''
            )}
            {this.state.openSignatureModal && (
              <SignatureModal
                closeModal={() => this.setState({ openSignatureModal: false })}
                open={this.state.openSignatureModal}
                attributes={this.state.signatureAttributes}
                handleDocumentUpdateAndDownload={
                  this.handleDocumentUpdateAndDownload
                }
              />
            )}
          </div>
        </div>
        <SnackbarMessage
          handleClose={this.handleClose}
          message={
            this.props.error.displayMessage ||
            this.props.success.displayMessage ||
            this.props.selectedMsg
          }
          open={this.props.open}
        />
        {this.state.openAlertDialog && (
          <AlertDailog
            open={this.state.openAlertDialog}
            message={this.state.alertMessage}
            handleClose={this.closeAlertDailog}
            showNoButton={false}
            yesButtonText={'Ok'}
          />
        )}
        {this.state.deleteDocDialog && (
          <AlertDailog
            open={this.state.deleteDocDialog}
            message={
              'Selected file will be deleted permanently. Are you sure you want to delete'
            }
            handleClose={this.closeDeleteDocDailog}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    open: state.reportReducer.open,
    error: state.reportReducer.error,
    success: state.reportReducer.success,
    selectedMsg: state.reportReducer.selectedMsg,
    getReportDetailsSuccess: state.reportReducer.getReportDetailsSuccess,
    reportDetailsSpinner: state.reportReducer.reportDetailsSpinner,
    reportDetails: state.reportReducer.reportDetails,
    createFormDataSuccess: state.reportReducer.createFormDataSuccess,
    createFormDataSpinner: state.reportReducer.createFormDataSpinner,
    createFormDataResponse: state.reportReducer.createFormDataResponse,
    timedOut: state.reportReducer.timedOut,
    userTeamDetails: state.homeReducer.userTeamDetails,
    teamUserDetailsSuccess: state.homeReducer.teamUserDetailsSuccess,
    externalProps: state.reportReducer.externalProps,
    teamdesignations: state.reportReducer.teamdesignations,
    teamdesignationsSpinner: state.reportReducer.teamdesignationsSpinner,
    teamdesignationsSuccess: state.reportReducer.teamdesignationsSuccess,
    teamdesignationsFailure: state.reportReducer.teamdesignationsFailure,
    otpFormSuccess: state.reportReducer.otpFormSuccess,
    otpFormFailure: state.reportReducer.otpFormFailure,
    otpFormData: state.reportReducer.otpFormData,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resetSuccessMessage,
      getReportDetails,
      createFormData,
      getTeamUserDetails,
      setSelectedFieldValue,
      handleImagesUploadAndDownload,
      handleDocumentUpload,
      getTeamDesignations,
      getOTPForForm,
      getUserDirectoryDesignation,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateLookupForm));

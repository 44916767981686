import { firebaseStorage } from './../../../../fireStoreConfig/fireStore';
import { isNull } from '../../../../utils/helper';
import { ref, deleteObject, getDownloadURL } from 'firebase/storage';
import { find } from 'lodash';

export const deleteDocumentFile = (
  attributes: any,
  filePath: any,
  formRef: any
) => {
  return deleteObject(ref(firebaseStorage, filePath))
    .then(() => {
      const values = attributes['value'] ? attributes['value'] : [];
      const updatedValues: any = [];
      if (!isNull(values)) {
        values.forEach((ele: any) => {
          const eleFilePath = !isNull(ele['file_path']) ? ele['file_path'] : '';
          if (eleFilePath !== filePath) updatedValues.push(ele);
        });
      }
      formRef.onValueChange(attributes['name'], updatedValues);
      return false;
    })
    .catch((err: any) => {
      return false;
    });
};

export const getAssignmentType = (assignmentType: any) => {
  switch (assignmentType) {
    case 'all':
      return 'ALL_USERS';
    case 'reportees':
      return 'ALL_REPORTEES';
    case 'managers':
      return 'ALL_MANAGERS';
    case 'tags_matching_division':
      return 'TAGS_MATCHING_DIVISION';
    case 'branch':
      return 'BRANCH';
    case 'department':
      return 'DEPARTMENT';
    case 'tags':
      return 'TAGS';
    case 'role':
      return 'ROLE';
    case 'work_location':
      return 'WORK_LOCATION';
    case 'state_name':
      return 'STATE_NAME';
    case 'division':
      return 'DIVISION';
    case 'region':
      return 'REGION';
    default:
      return 'ALL_USERS';
  }
};

export const downloadDocument = async (item: any) => {
  const filePath = item['file_path'] ? item['file_path'] : '';

  getDownloadURL(ref(firebaseStorage, filePath))
    .then((url: any) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const processUserDirectoryField = (
  field: any,
  designationData: any,
  userTeamDetails: any
) => {
  let filteredDesignations = designationData;
  let userDefaultValue: any = null;

  if (field.config.selected_designations?.length > 0) {
    filteredDesignations = designationData.filter((desig: any) =>
      field.config.selected_designations.includes(desig.desig_id)
    );
  }

  if (field.config?.default) {
    if (
      field.config.default === 'manager' &&
      userTeamDetails?.reports_to_designation
    ) {
      userDefaultValue = find(
        filteredDesignations,
        (designation: any) =>
          designation.desig_id ===
          userTeamDetails.reports_to_designation.designation_id
      );
    } else {
      userDefaultValue = find(
        filteredDesignations,
        (designation: any) => designation.desig_id === field.config.default
      );
    }
  }

  if (!isNull(userDefaultValue)) {
    field['defaultValue'] = {
      desig_id: userDefaultValue?.desig_id,
      desig_name: userDefaultValue?.desig_name,
      user_alias: userDefaultValue?.user_alias,
      user_id: userDefaultValue?.user_id,
    };
  } else if (
    field.config.show_first_option &&
    filteredDesignations.length === 1
  ) {
    field['defaultValue'] = {
      desig_id: filteredDesignations[0].desig_id,
      desig_name: filteredDesignations[0].desig_name,
      user_alias: filteredDesignations[0].user_alias,
      user_id: filteredDesignations[0].user_id,
    };
  }
};
